import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActiveTab, CrossLinksWrapper, GreyLayout, TabsWrapper } from './styles';
import TabCrossLink from '../TabCrossLink';
import { troikaBusinessLink, businessInvoiceboxLink, hotelOtelLink } from '../../../../utils/constants';

const TabsCrossLinks = () => {
    const { t } = useTranslation();

    return (
        <TabsWrapper>
            <ActiveTab>{t('Билеты на Аэроэкспресс')}</ActiveTab>
            <GreyLayout>
                <CrossLinksWrapper>
                    <TabCrossLink link={troikaBusinessLink} label={t('Карты Тройка и билеты')} colorIcon="#C83837" />
                    <TabCrossLink link={hotelOtelLink} label={t('Отели в Москве и МО')} colorIcon="#12B4F3" />
                    <TabCrossLink link={businessInvoiceboxLink} label={t('Товары и услуги для бизнеса')} />
                </CrossLinksWrapper>
            </GreyLayout>
        </TabsWrapper>
    );
};

export default TabsCrossLinks;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { ReactComponent as LogoEn } from '../../assets/icons/logo-en.svg';
import '../../i18/i18-translation';
import ISVG from '../../assets/icons/symbols-icon-header.svg';
import {
    AsideWrapper,
    AuthRegisterLinks,
    ButtonChangeLanguage,
    ButtonIconAuth,
    HeaderWrapper,
    LinkAuth,
    LogoLink,
} from './styles';
import { authLink, registerLink } from '../../utils/constants';

const Header = () => {
    const { t } = useTranslation();

    const [lang, setLang] = useState('ru');

    const toggleLangPage = () => {
        setLang((document.documentElement.lang = document.documentElement.lang === 'ru' ? 'en' : 'ru'));
    };

    return (
        <HeaderWrapper>
            <LogoLink href="/">{lang === 'en' ? <LogoEn /> : <Logo />}</LogoLink>
            <AsideWrapper>
                <ButtonChangeLanguage onClick={toggleLangPage}>{lang}</ButtonChangeLanguage>
                <AuthRegisterLinks>
                    <LinkAuth href={authLink}>{t('Вход')}</LinkAuth>
                    <span> / </span>
                    <LinkAuth href={registerLink}>{t('Регистрация')}</LinkAuth>
                </AuthRegisterLinks>
                <ButtonIconAuth href={authLink}>
                    <svg>
                        <use href={`${ISVG}#log-in`} />
                    </svg>
                </ButtonIconAuth>
            </AsideWrapper>
        </HeaderWrapper>
    );
};

export default Header;

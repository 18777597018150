import React from 'react';
import styled from 'styled-components';
import TabsCrossLinks from '../TabsCrossLinks';

const DesktopCrossLinksWrapper = styled.div`
    @media (max-width: 768px) {
        display: none;
    }
`;

const DesktopCrossLinks = () => (
    <DesktopCrossLinksWrapper>
        <TabsCrossLinks />
    </DesktopCrossLinksWrapper>
);

export default DesktopCrossLinks;

import styled from 'styled-components';

export const PhoneWrapper = styled.div`
    width: auto;
    display: inline-block;
    box-sizing: border-box;

    // styles for PhoneInput component from library
    .react-tel-input {
        height: 100%;
        display: flex;
        flex-direction: row-reverse;
    }

    .flag-dropdown {
        display: flex;
        align-items: center;
        background: transparent;
        border: none;
        position: static;
        border-radius: 10px !important;
    }

    .arrow {
        display: none;
    }

    .form-control {
        height: 100% !important;
        border-radius: 10px;
        width: calc(100% - 30px);
        background: transparent;
        padding: 0;
        border: none;
        font-family: 'Exo 2', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
            'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    }

    .selected-flag {
        width: 30px;
        overflow: hidden;
        padding-left: 4px !important;
    }

    .country-list {
        width: 300px;
        height: 200px;
        z-index: 10 !important;
        background: white !important;
    }

    .form-control.invalid-number {
        border: none !important;
        background: none;
    }
`;

const getColorBorder = (isError: boolean, isFocus: boolean) => {
    if (isFocus) {
        return '#353F47';
    }
    if (isError) {
        return '#DF1931';
    }
    return '#CBCBCB';
};

const getColorBorderHover = (isError: boolean, isFocus: boolean) => {
    if (isFocus) {
        return '#353F47';
    }
    if (isError) {
        return '#DF1931';
    }
    return '#353F47';
};

interface IPhoneInputWrapper {
    isError: boolean;
    isFocus: boolean;
}

export const PhoneInputWrapper = styled.div<IPhoneInputWrapper>`
    height: 46px;
    border: 1px solid ${({ isError, isFocus }) => getColorBorder(isError, isFocus)};
    border-radius: 10px;

    &:hover {
        border: 1px solid ${({ isError, isFocus }) => getColorBorderHover(isError, isFocus)};
    }
`;

export const ErrorMessage = styled.p`
    color: #df1931;
    margin: 0;
    line-height: unset;
    padding-top: 0.05rem;
    font-size: 0.65rem;
`;

import React, { FC } from 'react';
import InvoiceBoxShortLogo from '../../../../components/InvoiceBoxShortLogo';
import { Label, ButtonNotActiveTab, LinkIconArrow, Content } from './styles';

interface ITabCrossLink {
    label: string;
    colorIcon?: string;
    link: string;
}

const TabCrossLink: FC<ITabCrossLink> = ({ label, colorIcon, link }) => {
    const handleButtonClick = () => {
        window.open(link, '_blank');
    };

    return (
        <ButtonNotActiveTab onClick={handleButtonClick}>
            <Content>
                <InvoiceBoxShortLogo color={colorIcon} />
                <Label>{label}</Label>
            </Content>
            <LinkIconArrow />
        </ButtonNotActiveTab>
    );
};

export default TabCrossLink;

import { css } from 'styled-components';

const start = `
    display: flex;
    align-items: flex-start;
`;

const end = `
    display: flex;
    align-items: flex-end;
`;

const center = `
    display: flex;
    align-items: center;
`;

const mixins = {
    flexStart: css`
        ${start}
    `,
    flexEnd: css`
        ${end}
    `,
    flexCenter: css`
        ${center}
    `,
    flexStartCenter: css`
        ${start};
        justify-content: center;
    `,
    flexCenterCenter: css`
        ${center};
        justify-content: center;
    `,
    flexEndCenter: css`
        ${end};
        justify-content: center;
    `,
    resetButtonStyles: css`
        padding: 0;
        border: none;
        font: inherit;
        color: inherit;
        background-color: transparent;
        cursor: pointer;
    `,
};

export default mixins;

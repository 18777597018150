import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import Header from './components/Header';
import BreadCrumbs from './components/BreadCrumbs';
import AeroExpressBusiness from './pages/Content/index';
import Footer from './components/Footer';
import theme from './theme';

const App = () => {
    const [isShowRulesModal, setIsShopRulesModal] = useState(false);
    const [isShowTariffsModal, setIsShowTariffsModal] = useState(false);

    const toggleTariffsModal = (e: React.MouseEvent) => {
        setIsShowTariffsModal(!isShowTariffsModal);
        return e.preventDefault();
    };

    const toggleRulesModal = (e: React.MouseEvent) => {
        setIsShopRulesModal(!isShowRulesModal);
        return e.preventDefault();
    };

    return (
        <ThemeProvider theme={theme}>
            <Header />
            <BreadCrumbs />
            <AeroExpressBusiness
                toggleTariffsModal={toggleTariffsModal}
                isShowTariffsModal={isShowTariffsModal}
                isShowRulesModal={isShowRulesModal}
                toggleRulesModal={toggleRulesModal}
            />
            <Footer toggleTariffsModal={toggleTariffsModal} toggleRulesModal={toggleRulesModal} />
        </ThemeProvider>
    );
};

export default App;

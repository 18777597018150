export enum ORDER_DATA_FIELDS {
    order = 'order',
    tickets = 'tickets',
}

export enum ORDER_FIELDS {
    paymentStatusLabel = 'paymentStatusLabel',
    ticketStatusLabel = 'ticketStatusLabel',
    id = 'id',
    uid = 'uid',
    paymentStatus = 'paymentStatus',
    ticketStatus = 'ticketStatus',
    orderId = 'orderId',
    departDate = 'departDate',
    price = 'price',
    ticketsCount = 'ticketsCount',
    tariffId = 'tariffId',
    tariffName = 'tariffName',
    reservedUntil = 'reservedUntil',
    ucode = 'ucode',
    payedAt = 'payedAt',
    canceledAt = 'canceledAt',
    createdAt = 'createdAt',
    clientId = 'clientId',
    organization = 'organization',
    kpp = 'kpp',
    tin = 'tin',
    name = 'name',
    nameFull = 'nameFull',
    addressYuristic = 'addressYuristic',
}

export interface IOrder {
    [ORDER_FIELDS.paymentStatusLabel]: string;
    [ORDER_FIELDS.ticketStatusLabel]: string;
    [ORDER_FIELDS.id]: number;
    [ORDER_FIELDS.uid]: string;
    [ORDER_FIELDS.paymentStatus]: string;
    [ORDER_FIELDS.ticketStatus]: string;
    [ORDER_FIELDS.orderId]: number;
    [ORDER_FIELDS.departDate]: string;
    [ORDER_FIELDS.price]: number;
    [ORDER_FIELDS.ticketsCount]: number;
    [ORDER_FIELDS.tariffId]: number;
    [ORDER_FIELDS.tariffName]: string;
    [ORDER_FIELDS.reservedUntil]: string;
    [ORDER_FIELDS.ucode]: string;
    [ORDER_FIELDS.payedAt]: string;
    [ORDER_FIELDS.canceledAt]: string;
    [ORDER_FIELDS.createdAt]: string;
    [ORDER_FIELDS.clientId]: number;
    [ORDER_FIELDS.organization]: {
        [ORDER_FIELDS.kpp]: string;
        [ORDER_FIELDS.tin]: string;
        [ORDER_FIELDS.name]: string;
        [ORDER_FIELDS.nameFull]: string;
        [ORDER_FIELDS.addressYuristic]: string;
    };
}

export enum TICKET_FIELDS {
    id = 'id',
    orderId = 'orderId',
    code = 'code',
    ticketId = 'ticketId',
    ticketGuid = 'ticketGuid',
    ticketUrl = 'ticketUrl',
    token = 'token',
    tripDate = 'tripDate',
    tripTime = 'tripTime',
    stArrival = 'stArrival',
    stDepart = 'stDepart',
    tariff = 'tariff',
    price = 'price',
    createdAt = 'createdAt',
}

export interface ITicket {
    [TICKET_FIELDS.id]: number;
    [TICKET_FIELDS.orderId]: number;
    [TICKET_FIELDS.code]: string;
    [TICKET_FIELDS.ticketId]: number;
    [TICKET_FIELDS.ticketGuid]: string;
    [TICKET_FIELDS.ticketUrl]: string;
    [TICKET_FIELDS.token]: string;
    [TICKET_FIELDS.tripDate]: string;
    [TICKET_FIELDS.tripTime]: string;
    [TICKET_FIELDS.stArrival]: string;
    [TICKET_FIELDS.stDepart]: string;
    [TICKET_FIELDS.tariff]: string;
    [TICKET_FIELDS.price]: number;
    [TICKET_FIELDS.createdAt]: string;
}

export interface IOrderData {
    [ORDER_DATA_FIELDS.order]: IOrder;
    [ORDER_DATA_FIELDS.tickets]: ITicket[];
}

export type TTariff = {
    id: number;
    name: string;
    description: string;
    days_from: number;
    days_to: number;
    seats_select: boolean;
    active: boolean;
    price: number;
    max_tickets: number;
    provider_tariff_id: number;
    created_at: string;
};

import React, { FC } from 'react';
import { TTariff } from '../../../utils/types';

type Props = {
    tariffs?: TTariff[];
};

const tariffPriceFormatter = (price?: number) => {
    if (!price) return '';

    return price
        .toLocaleString('ru-RU', {
            style: 'currency',
            currency: 'RUB',
        })
        .replace(/[,].*/, '');
};

const ModalTariffsDescription: FC<Props> = ({ tariffs }) => {
    if (!tariffs) {
        return null;
    }

    return (
        <div>
            {tariffs.map((tariff) => (
                <div key={tariff.id}>
                    <h3>
                        <dfn>{tariff.name}</dfn>
                        <span>{tariffPriceFormatter(tariff.price)}</span>
                    </h3>
                    <p
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: tariff.description || 'Нет данных' }}
                    />
                </div>
            ))}
        </div>
    );
};

export default ModalTariffsDescription;

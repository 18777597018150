import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translateEN from './translation-en.json';

const resources = {
    en: {
        translation: translateEN,
    },
    ru: {
        translation: {},
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'ru',
    interpolation: { escapeValue: false },
});

export default i18n;

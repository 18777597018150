import styled from 'styled-components';
import giftImage from './assets/gift.png';

export const Wrapper = styled.a`
    display: block;
    border-radius: 20px;
    border: 1px solid #e8f2ff;
    background: url(${giftImage}) no-repeat right bottom -30px, #fafcff;
    padding: 24px 40px;
    box-sizing: border-box;
    width: 730px;
    height: 160px;
    text-decoration: none !important;

    &:focus {
        background-color: #fafcff !important;
    }

    @media (max-width: 767px) {
        width: 100%;
        height: 364px;
        background-position: center bottom 72px;
    }
`;

export const Content = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 767px) {
        align-items: center;
    }
`;

export const FakeButtonWrapper = styled.div`
    display: contents;

    @media (max-width: 767px) {
        width: 100%;
        display: block;
        padding-top: 24px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #f7faff 46.38%);
    }
`;

export const FakeButton = styled.div`
    border-radius: 50px;
    background: #fad247;
    padding: 6px 24px 8px 24px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #353f47;
    box-sizing: border-box;
    text-align: center;

    @media (max-width: 767px) {
        padding: 13px 24px;
    }
`;

export const Description = styled.p`
    color: #464646;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    width: 285px;
    margin: 0;

    @media (max-width: 767px) {
        width: 248px;
    }
`;

export const Accent = styled.span`
    color: #464646;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
`;

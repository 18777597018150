import styled from 'styled-components';

export const FooterWrapper = styled.footer`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    height: 70px;
    background: #f3f3f3;
    box-sizing: border-box;
    @media (max-width: 767px) {
        height: auto;
    }
`;

export const FooterContent = styled.section`
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    width: 100%;
    @media (max-width: 1200px) {
        padding: 0 40px;
    }
    @media (max-width: 767px) {
        display: grid;
        grid-template-columns: 1fr;
        padding: 17px 0 20px 0;
        grid-row-gap: 35px;
    }
`;

export const LogoLink = styled.a`
    margin-right: 92px;
    svg {
        transform: translateY(0.2rem);
    }

    @media (max-width: 1200px) {
        margin-right: 40px;
    }
    @media (max-width: 767px) {
        margin: auto;
        grid-row: 3;
    }
`;

export const ButtonRulesService = styled.button`
    ${({ theme }) => theme.mixins.resetButtonStyles};
    color: #8f969c;
`;

export const CreatorService = styled.span`
    ${({ theme }) => theme.mixins.flexCenter};

    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #8f969c;

    @media (max-width: 767px) {
        justify-content: center;
    }
`;

export const FooterLinks = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 30px;

    @media (max-width: 767px) {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 35px;
        grid-row: 1;
    }
`;

import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RestApi from '../../../../utils/http';
import { IOrderData, ORDER_DATA_FIELDS, ORDER_FIELDS } from '../../../../utils/types';
import { getPaymentStatusLabel, PAYMENT_STATUSES, TICKET_STATUSES } from './types';
import RenderContentResult from '../RenderContentResult';
import FlocktoryBanner from '../../../../components/FloctoryBanner';
import * as S from './styles';

interface IResultTicket {
    setError: (arg: any) => void;
    setLoading: (arg: boolean) => void;
}

const ResultTicket: FC<IResultTicket> = ({ setError, setLoading }) => {
    const { t } = useTranslation();

    const [order, setOrder] = useState<IOrderData>();

    const getTicketId = useCallback(
        (id: string | false) => {
            if (id && !order?.order?.id) {
                RestApi.get(`public/orders/${id}`)
                    .catch((err) => {
                        setError(err);
                        setLoading(false);
                    })
                    .then((response) => response && response.data)
                    .then((resource) => resource && setOrder(resource.data));
            }
        },
        [order?.order?.id, setError, setLoading],
    );

    const orderId = window.location.pathname.includes('order') && window.location.pathname.replace(/\/*order\//, '');

    useEffect(() => {
        getTicketId(orderId);
    }, [getTicketId, orderId]);

    const resetData = () => {
        window.location.href = '/';
    };

    const gotoAeroexpress = () => {
        window.location.href = 'https://aeroexpressbusiness.ru';
    };

    const orderIsPaidAndIssued =
        order?.order?.paymentStatus === PAYMENT_STATUSES.PAYMENT_STATUS_PAID &&
        order?.order?.[ORDER_FIELDS.ticketStatus] === TICKET_STATUSES.TICKET_STATUS_ISSUED;
    const isOnlyOneTicket = order?.[ORDER_DATA_FIELDS.order]?.[ORDER_FIELDS.ticketsCount] === 1;

    return (
        <S.Wrapper id="mode-final" data-open={order || undefined}>
            <section>
                <div>
                    <p>
                        <s>{t('Заказ')} </s>
                        <b>№ {order && order.order && order.order.orderId}</b>
                        <span>{getPaymentStatusLabel(t, order?.order?.paymentStatus as PAYMENT_STATUSES)}. </span>
                        {orderIsPaidAndIssued && (
                            <span>
                                <span>
                                    {isOnlyOneTicket
                                        ? t('Билет выслан на ваш e-mail')
                                        : t('Билеты высланы на ваш e-mail')}
                                </span>
                            </span>
                        )}
                    </p>
                    {order && (
                        <RenderContentResult
                            isOnlyOneTicket={isOnlyOneTicket}
                            orderIsPaidAndIssued={orderIsPaidAndIssued}
                            order={order}
                        />
                    )}
                </div>
                <div />
            </section>
            {orderIsPaidAndIssued && (
                <S.FlocktoryWrapper>
                    <FlocktoryBanner />
                </S.FlocktoryWrapper>
            )}
            <S.Buttons>
                <S.Button type="button" className="active" onClick={resetData}>
                    {t('Забронировать ещё')}
                </S.Button>
                <S.Button type="button" onClick={gotoAeroexpress}>
                    {t('На главную')} {t('Аэроэкспресса')}
                </S.Button>
            </S.Buttons>
        </S.Wrapper>
    );
};

export default ResultTicket;

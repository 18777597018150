import React from 'react';
import styled from 'styled-components';
import TabsCrossLinks from '../TabsCrossLinks';

const MobileCrossLinksWrapper = styled.div`
    display: none;
    @media (max-width: 768px) {
        display: block;
    }
`;

const MobileCrossLinks = () => (
    <MobileCrossLinksWrapper>
        <TabsCrossLinks />
    </MobileCrossLinksWrapper>
);

export default MobileCrossLinks;

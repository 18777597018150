import React from 'react';
import './index.css';
import '../../i18/i18-translation';
import { withTranslation } from 'react-i18next';

class BreadCrumbs extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = { lang: (document.documentElement.lang = 'ru') };
    }

    render() {
        return (
            <div className="bread-crumbs">
                <a href="https://aeroexpress.ru/?utm_source=aeroexpress_business"> ← {this.props.t('На сайт Аэроэкспресс')} </a>
            </div>
        );
    }
}

export default withTranslation()(BreadCrumbs);

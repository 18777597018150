import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 0;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding: 40px 0;

    &[data-open] ~ * {
        display: none;
    }

    &:not([data-open]) {
        display: none;
    }

    @media (max-width: 767px) {
        padding: 30px 0;
        gap: 36px;
    }
`;

export const Buttons = styled.div`
    display: flex;
    align-items: center;
    gap: 30px;
    min-width: 1200px;

    @media (max-width: 1200px) {
        min-width: 100vw;
        padding: 0 40px;
        box-sizing: border-box;
    }

    @media (max-width: 767px) {
        flex-direction: column;
        gap: 16px;
    }
`;

export const Button = styled.button`
    width: 260px;
    height: 37px;

    @media (max-width: 767px) {
        width: 214px;
    }
`;

export const FlocktoryWrapper = styled.div`
    min-width: 1200px;

    @media (max-width: 1200px) {
        min-width: 100vw;
        padding: 0 40px;
        box-sizing: border-box;
    }

    @media (max-width: 767px) {
        padding: 0 16px;
    }
`;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as StarIsLoad } from '../../../../assets/icons/start-is-load.svg';
import ISVG from '../../../../assets/icons/symbols-icon.svg';
import { IOrderData, ITicket, ORDER_DATA_FIELDS, ORDER_FIELDS, TICKET_FIELDS } from '../../../../utils/types';
import { PAYMENT_STATUSES, TICKET_STATUSES } from '../ResultTicket/types';
import { ButtonDownloadTicket, Ticket, TicketName, Tickets } from './styles';

interface IRenderContentResult {
    orderIsPaidAndIssued: boolean;
    order: IOrderData;
    isOnlyOneTicket: boolean;
}

const RenderContentResult: FC<IRenderContentResult> = ({ isOnlyOneTicket, orderIsPaidAndIssued, order }) => {
    const { t } = useTranslation();

    const downloadTicket = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, ticketLink: string) => {
        window.open(ticketLink, '_blank');
        return e.preventDefault();
    };

    if (orderIsPaidAndIssued) {
        const renderTicketLinks = (tickets: ITicket[]) =>
            tickets?.map((ticketItem, index) => {
                const ticketUrl = ticketItem?.[TICKET_FIELDS.ticketUrl];
                return (
                    <Ticket key={ticketUrl}>
                        <TicketName>{isOnlyOneTicket ? t('Билет') : t('Билет № ') + (index + 1)}</TicketName>
                        <ButtonDownloadTicket onClick={(e) => downloadTicket(e, ticketUrl)}>
                            <i>
                                <svg className="dowload-link">
                                    <use href={`${ISVG}#doc-pdf`} />
                                </svg>
                            </i>
                        </ButtonDownloadTicket>
                    </Ticket>
                );
            });

        return (
            <>
                <p>
                    {isOnlyOneTicket
                        ? t('Вы также можете скачать билет прямо с этой страницы')
                        : t('Вы также можете скачать билеты прямо с этой страницы')}
                    .
                </p>
                <div>
                    <i>
                        <StarIsLoad />
                    </i>
                    <Tickets>
                        <span>{isOnlyOneTicket ? t('Скачать билет') : t('Скачать билеты')}</span>
                        {renderTicketLinks(order?.[ORDER_DATA_FIELDS.tickets])}
                    </Tickets>
                </div>
            </>
        );
    }

    if (order?.[ORDER_DATA_FIELDS.order]?.[ORDER_FIELDS.ticketStatus] === TICKET_STATUSES.TICKET_STATUS_CANCELED) {
        return <p>{t('Ваш заказ отменён')}.</p>;
    }

    if (order?.[ORDER_DATA_FIELDS.order]?.[ORDER_FIELDS.ticketStatus] === TICKET_STATUSES.TICKET_STATUS_ERROR) {
        return <p>{t('Ошибка выпуска билетов')}.</p>;
    }

    if (
        order?.[ORDER_DATA_FIELDS.order]?.[ORDER_FIELDS.ticketStatus] === TICKET_STATUSES.TICKET_STATUS_NOT_ISSUED &&
        order?.[ORDER_DATA_FIELDS.order]?.[ORDER_FIELDS.paymentStatus] === PAYMENT_STATUSES.PAYMENT_STATUS_PAID
    ) {
        return <p>{t('Ожидается выпуск билетов')}.</p>;
    }
    return null;
};

export default RenderContentResult;

import React, { FC } from 'react';

interface IInvoiceBoxShortLogo {
    color?: string;
}

const InvoiceBoxShortLogo: FC<IInvoiceBoxShortLogo> = ({ color = '#FAD247' }) => (
    <svg width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.3579 18.5158C14.2236 19.4746 12.731 19.9396 11.3319 19.8219C9.92872 19.7195 8.62339 19.0335 7.78712 18.0258C7.54241 17.731 7.58549 17.2954 7.88327 17.0532C8.14486 16.8405 8.52205 16.8524 8.77429 17.057C10.2922 18.2856 12.5089 17.8461 13.4006 16.5609C13.8416 15.9253 14.7195 15.7639 15.3615 16.2006C16.0036 16.6373 16.1665 17.5065 15.7255 18.1421C15.6553 18.2433 15.5739 18.3325 15.4844 18.4091L15.3579 18.5158Z"
            fill={color}
        />
        <path
            d="M20.3859 8.46521C22.1062 8.46521 23.5058 9.91876 23.5058 11.7052C23.5058 13.4917 22.1062 14.9449 20.3859 14.9449C18.6656 14.9449 17.266 13.4917 17.266 11.7052C17.266 9.91876 18.6656 8.46521 20.3859 8.46521ZM20.3859 5.96094C17.331 5.96094 14.8545 8.53271 14.8545 11.7052C14.8545 14.8775 17.331 17.4492 20.3859 17.4492C23.4408 17.4492 25.9173 14.8775 25.9173 11.7052C25.9173 8.53271 23.4408 5.96094 20.3859 5.96094Z"
            fill={color}
        />
        <path
            d="M4.31924 10.9598C5.42905 10.9598 6.3318 11.8974 6.3318 13.0498C6.3318 14.2022 5.42892 15.1396 4.31924 15.1396C3.20955 15.1396 2.30668 14.202 2.30668 13.0497C2.30668 11.8973 3.20955 10.9598 4.31924 10.9598ZM4.31924 8.65039C1.97947 8.65039 0.0827637 10.62 0.0827637 13.0498C0.0827637 15.4796 1.97947 17.4492 4.31924 17.4492C6.65901 17.4492 8.55571 15.4797 8.55571 13.0498C8.55571 10.6199 6.65901 8.65039 4.31924 8.65039Z"
            fill="#353F47"
        />
    </svg>
);

export default InvoiceBoxShortLogo;

import React from 'react';
import { useTranslation } from 'react-i18next';
import ISVG from '../../assets/icons/symbols-icon.svg';
import { ReactComponent as POPIS } from '../../pages/Content/StaticText/rules/popis.svg';
import { ReactComponent as POPISEN } from '../../pages/Content/StaticText/rules/popis-en.svg';

// todo переписать на tsx с модалкой через порталы при рефакторинге модалок и достаточном времени на это
const ModalRulesService = ({ lang, toggleOpenModal, isShowModal, toggleStop }) => {
    const { t } = useTranslation();

    return (
        <div
            id="modal-popis"
            className="modal-info"
            data-open={isShowModal || undefined}
            onClick={toggleOpenModal}
        >
            <section onClick={toggleStop}>
                <div onClick={toggleOpenModal}>
                    <s />
                    <dfn>← {t('Назад')}</dfn>
                </div>
                <del onClick={toggleOpenModal}>
                    <svg>
                        {' '}
                        <use href={`${ISVG}#set-close`} />{' '}
                    </svg>
                </del>
                <h2>{t('Публичная оферта на оказание информационных услуг')}</h2>
                {lang === 'ru' ? <POPIS /> : <POPISEN />}
            </section>
        </div>
    );
};

export default ModalRulesService;

import axios from 'axios';
import { IS_DEV } from './constants';

const RestApi = axios.create({
    baseURL: IS_DEV ? 'https://aeroexpress.stage.invbox.ru/api' : '/api',
    transformRequest: [
        (data, headers) => {
            headers.common['Accept-Language'] = document.documentElement.lang;
            return data;
        },
    ],
});
export default RestApi;

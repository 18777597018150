import styled from 'styled-components';

export const Tickets = styled.div`
    ${({ theme }) => theme.mixins.flexStart};
    flex-direction: column;
    grid-row-gap: 8px;
    padding-top: 24px;
`;

export const ButtonDownloadTicket = styled.a`
    ${({ theme }) => theme.mixins.resetButtonStyles};
`;

export const Ticket = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 24px;
`;

export const TicketName = styled.p`
    margin: 0 !important;
`;

import styled from 'styled-components';
import { tabStyles } from '../TabsCrossLinks/styles';
import { ReactComponent as LinkIcon } from '../../../../assets/icons/arrowLink.svg';

export const LinkIconArrow = styled(LinkIcon)`
    transition: transform 0.3s ease-in-out 0s;

    @media (max-width: 1200px) {
        display: none;
    }
    @media (max-width: 768px) {
        display: block;
    }
`;

export const ButtonNotActiveTab = styled.button`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 6px;
    ${tabStyles};
    background: #f3f3f3;
    border-radius: 12px;
    padding-right: 12px;
    cursor: pointer;

    &:hover {
        background: #e6e6e6;
        ${LinkIconArrow} {
            transform: translate(2px, -2px);
        }
    }
    &:focus {
        color: #4e5962;
    }
    @media (max-width: 1200px) {
        padding-right: 20px;
    }
    @media (max-width: 768px) {
        width: 100%;
        max-width: 320px;
        justify-content: space-between;
    }
`;

export const Label = styled.p`
    margin: 0;
    margin-left: 2px;
    color: #4e5962;
    font-size: 14px;
    white-space: nowrap;
`;

export const Content = styled.div`
    display: contents;
    @media (max-width: 768px) {
        ${({ theme }) => theme.mixins.flexCenter};
        grid-column-gap: 8px;
    }
`;

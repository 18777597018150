import styled, { css } from 'styled-components';

export const tabStyles = css`
    padding: 7px 20px;
    font-weight: 400;
    line-height: 22px;
    color: #4e5962;
    @media (max-width: 1200px) {
        padding-bottom: 8px;
    }
`;

export const TabsWrapper = styled.div`
    display: flex;
    @media (max-width: 768px) {
        margin-top: 20px;
    }
`;

export const ActiveTab = styled.div`
    ${tabStyles};
    background: #fafafa;
    border-radius: 16px 12px 0 0;
    padding-bottom: 15px;
    white-space: nowrap;
    @media (max-width: 1200px) {
        padding-bottom: 12px;
    }
    @media (max-width: 768px) {
        display: none;
    }
`;

export const GreyLayout = styled.div`
    background: #fafafa;
    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const CrossLinksWrapper = styled.div`
    background: white;
    border-radius: 0 0 0 12px;
    height: 100%;
    ${({ theme }) => theme.mixins.flexStart};
    grid-column-gap: 8px;
    padding-left: 8px;

    @media (max-width: 1200px) {
        grid-column-gap: 4px;
        padding-left: 4px;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        grid-row-gap: 20px;
        align-items: center;
    }
`;

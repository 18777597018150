import { TFunction } from 'i18next';

export enum PAYMENT_STATUSES {
    PAYMENT_STATUS_NOT_PAID = 'not_paid',
    PAYMENT_STATUS_PAID = 'paid',
    PAYMENT_STATUS_ERROR = 'error',
    PAYMENT_STATUS_CANCELED = 'canceled',
}

export const getPaymentStatusLabel = (t: TFunction, status?: PAYMENT_STATUSES) => {
    if (status) {
        const labelStatus = {
            [PAYMENT_STATUSES.PAYMENT_STATUS_NOT_PAID]: t('не оплачен'),
            [PAYMENT_STATUSES.PAYMENT_STATUS_PAID]: t('оплачен'),
            [PAYMENT_STATUSES.PAYMENT_STATUS_ERROR]: t('произошла ошибка с оплатой'),
            [PAYMENT_STATUSES.PAYMENT_STATUS_CANCELED]: t('отменён'),
        };
        return labelStatus[status];
    }
};

export enum TICKET_STATUSES {
    TICKET_STATUS_NOT_ISSUED = 'not_issued',
    TICKET_STATUS_ISSUED = 'issued',
    TICKET_STATUS_ERROR = 'error',
    TICKET_STATUS_CANCELED = 'canceled',
}

import styled from 'styled-components';

export const HeaderWrapper = styled.header`
    overflow: hidden;
    background: #353f47;
    ${({ theme }) => theme.mixins.flexCenter};

    justify-content: space-between;
    height: 70px;
    box-sizing: border-box;
    gap: 1rem;
    color: #f3f3f3;
    padding: 0 2rem;
    @media (max-width: 767px) {
        padding: 0 15px;
        height: 50px;
    }
`;

export const AsideWrapper = styled.aside`
    height: 30px;
    ${({ theme }) => theme.mixins.flexCenter};

    gap: 1ex;
    @media (max-width: 767px) {
        gap: 0;
        height: 24px;
    }
`;

export const LogoLink = styled.a`
    color: #f3f3f3;
    svg {
        fill: #fafafa;
    }
    @media (max-width: 767px) {
        transform: scale(0.65);
        transform-origin: left;
    }
`;

export const ButtonChangeLanguage = styled.button`
    ${({ theme }) => theme.mixins.resetButtonStyles};
    ${({ theme }) => theme.mixins.flexCenterCenter};

    cursor: pointer;
    text-transform: capitalize;

    padding-right: 12px;
    margin-right: 12px;
    border-right: 1px solid #535d65;
    height: 100%;
    @media (max-width: 767px) {
        margin-right: 10px;
    }
`;

export const LinkAuth = styled.a`
    text-decoration: none;
    color: #f3f3f3;
`;

export const ButtonIconAuth = styled.a`
    width: 24px;
    height: 24px;
    fill: #f3f3f3;
`;

export const AuthRegisterLinks = styled.div`
    @media (max-width: 767px) {
        display: none;
    }
`;

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../../assets/icons/logoInvoicebox.svg';
import '../../i18/i18-translation';
import { ButtonRulesService, CreatorService, FooterContent, FooterLinks, FooterWrapper, LogoLink } from './styles';

interface IFooter {
    toggleRulesModal: (e: React.MouseEvent) => void;
    toggleTariffsModal: (e: React.MouseEvent) => void;
}

const Footer: FC<IFooter> = ({ toggleRulesModal, toggleTariffsModal }) => {
    const { t } = useTranslation();
    const currentYear = new Date().getFullYear();

    return (
        <FooterWrapper>
            <FooterContent>
                <FooterLinks>
                    <LogoLink
                        href="https://www.invoicebox.ru/?utm_source=aeroexpress_footer"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Logo />
                    </LogoLink>
                    <ButtonRulesService onClick={toggleTariffsModal}>{t('Тарифы')}</ButtonRulesService>
                    <ButtonRulesService onClick={toggleRulesModal}>
                        {t('Правила пользования сервисом')}
                    </ButtonRulesService>
                </FooterLinks>
                <CreatorService>
                    © {currentYear}, «{t('ООО ОРЦ')}»
                </CreatorService>
            </FooterContent>
        </FooterWrapper>
    );
};

export default Footer;

import React, { FC, useState } from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, PhoneInputWrapper, PhoneWrapper } from './styles';
import { countNumbersInPhoneMask } from '../../utils/helpers';
interface IPhoneInputField {
    onChange: (arg: string | null) => void;
}

const PhoneInputField: FC<IPhoneInputField> = ({ onChange }) => {
    const { t } = useTranslation();

    const [phone, setPhone] = useState<string>();
    const [isFirstBlur, setIsFirstBlur] = useState(true);
    const [isError, setIsError] = useState(false);
    const [isFocus, setIsFocus] = useState(false);

    const onBlur = (event: React.FocusEvent<HTMLInputElement>, country: CountryData) => {
        if (countNumbersInPhoneMask(country?.format) !== phone?.length) {
            setIsError(true);
        } else {
            setIsError(false);
        }
        setIsFocus(false);
        if (isFirstBlur) {
            setIsFirstBlur(false);
        }
    };

    const onChangeInput = (phoneValueInput: string, country: CountryData) => {
        setPhone(phoneValueInput);
        const isValidByCountSymbols = countNumbersInPhoneMask(country?.format) === phoneValueInput?.length;
        const isValidByDialCode = phoneValueInput[0] === country?.dialCode[0];
        const isValidValue = isValidByCountSymbols && isValidByDialCode;
        if (isValidValue) {
            onChange(phoneValueInput);
        } else {
            onChange(null);
        }
        if (!isFirstBlur) {
            setIsError(!isValidValue);
        }
    };

    const onFocus = () => {
        setIsFocus(true);
    };

    return (
        <PhoneWrapper>
            <PhoneInputWrapper isError={isError} isFocus={isFocus}>
                <PhoneInput
                    onFocus={onFocus}
                    onChange={onChangeInput}
                    onBlur={onBlur}
                    preferredCountries={['ru', 'us']}
                    country="ru"
                />
            </PhoneInputWrapper>
            {isError && <ErrorMessage>{t('Введите телефон в правильном формате!')}</ErrorMessage>}
        </PhoneWrapper>
    );
};

export default PhoneInputField;

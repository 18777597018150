import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

const FLOCKTORY_URL =
    'https://cnt.invoicebox.ru/www/delivery/cl.php?bannerid=11&zoneid=0&log=no&sig=df9d874a71abe1af2ae3af0fc7e84b4d7d7309c76e346693e9d35e3ac81a73ec&dest=https%3A%2F%2Fshare.flocktory.com%2Fexchange%2Flogin%3Fssid%3D5799%26bid%3D9824';

const FlocktoryBanner = () => {
    const { t } = useTranslation();

    return (
        <S.Wrapper target="_blank" href={FLOCKTORY_URL}>
            <S.Content>
                <S.Description>
                    {t('Вам доступен')}
                    <br />
                    <S.Accent>{t('(1) подарок')}</S.Accent> {t('за заказ')}
                </S.Description>
                <S.FakeButtonWrapper>
                    <S.FakeButton>{t('Выбрать')}</S.FakeButton>
                </S.FakeButtonWrapper>
            </S.Content>
        </S.Wrapper>
    );
};

export default FlocktoryBanner;
